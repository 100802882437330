import Image from "next/image";
import arrowRight from "../../public/images/icons/angle-right.png";
import arrowLeft from "../../public/images/icons/angle-left.png";
import styles from "../styles/ListPager.module.scss";

const ListPager = ({
  miniPagination = false,
  totalCount,
  numberPerPage,
  currentPage,
  onPageClick,
}) => {
  const loader = ({ src, width, quality }) => {
    const imageUrl = process.env.SITE_URL;
    return `${imageUrl}${src}?w=${width}&q=${quality || 75}`;
  };

  const maxNumberOfPages = Math.ceil(totalCount / numberPerPage);

  const getMobilePagerButton = (key, pageNumber, nextNumber, isActive) => {
    const className = `${styles.number} ${isActive ? styles.active : ""}`;
    return (
      <button
        className={className}
        key={key}
        onClick={() => {
          if (nextNumber) onPageClick(nextNumber);
        }}
      >
        {pageNumber}
      </button>
    );
  };

  // Mobile
  let mobilePagerButtons = [];
  if (maxNumberOfPages === 1) {
    mobilePagerButtons.push(getMobilePagerButton(0, currentPage, null, true));
  } else if (maxNumberOfPages === 2) {
    if(currentPage === 2) {
      mobilePagerButtons.push(
        getMobilePagerButton(0, 1, 1)
      );
    }
    mobilePagerButtons.push(getMobilePagerButton(1, currentPage, null, true));
    if(currentPage === 1) {
      mobilePagerButtons.push(
        getMobilePagerButton(2, 2, 2)
      );
    }
  } else if (currentPage % 3 === 1) {
    mobilePagerButtons.push(getMobilePagerButton(0, currentPage, null, true));
    if(currentPage + 1 <= maxNumberOfPages) {
      mobilePagerButtons.push(
        getMobilePagerButton(1, currentPage + 1, currentPage + 1)
      );
    }
    if(currentPage + 2 <= maxNumberOfPages) {
      mobilePagerButtons.push(
        getMobilePagerButton(2, currentPage + 2, currentPage + 2)
      );
    }
  } else if (currentPage % 3 === 2) {
    mobilePagerButtons.push(
      getMobilePagerButton(0, currentPage - 1, currentPage - 1)
    );
    mobilePagerButtons.push(getMobilePagerButton(1, currentPage, null, true));
    if(currentPage + 1 <= maxNumberOfPages) {
      mobilePagerButtons.push(
        getMobilePagerButton(2, currentPage + 1, currentPage + 1)
      );
    }
  } else {
    mobilePagerButtons.push(
      getMobilePagerButton(0, currentPage - 2, currentPage - 2)
    );
    mobilePagerButtons.push(
      getMobilePagerButton(1, currentPage - 1, currentPage - 1)
    );
    mobilePagerButtons.push(getMobilePagerButton(2, currentPage, null, true));
  }

  if (totalCount > numberPerPage * 2) {
    if (maxNumberOfPages >= 4 ) {
      mobilePagerButtons.push(
        <div className={styles.dots} key={3}>
          ...
        </div>
      );
    }
    mobilePagerButtons.push(
      <button
        className={styles.number}
        onClick={() => onPageClick(maxNumberOfPages)}
        key={4}
      >
        {maxNumberOfPages}
      </button>
    );
  }

  // Desktop
  let desktopPagerButtons = [];
  for (let i = 0; i < maxNumberOfPages; i++) {
    const classNames = `${styles.number} ${
      i + 1 === currentPage ? styles.active : ""
    }`;
    desktopPagerButtons.push(
      <button
        className={classNames}
        onClick={() => onPageClick(i + 1)}
        key={i + 1}
      >
        {i + 1}
      </button>
    );
  }

  return (
    <div className={styles.pagination}>
      {currentPage > 1 && maxNumberOfPages >= 5 && (
        <button
          className={miniPagination ? `${styles.arrow} ${styles.miniPaginationArrow}` : styles.arrow}
          onClick={() => onPageClick(currentPage > 1 ? currentPage - 1 : 1)}
        >
          <Image
            loader={loader}
            src={arrowLeft}
            alt="arrow left"
            width={8}
            height={12}
          />
        </button>
      )}

      <div className={styles.paginationMobile}>{maxNumberOfPages >= 5 ? mobilePagerButtons : desktopPagerButtons}</div>
      <div className={styles.paginationDesktop}>{!miniPagination ? desktopPagerButtons : mobilePagerButtons}</div>

      {currentPage < maxNumberOfPages && maxNumberOfPages >= 5 && (
        <button
          className={miniPagination ? `${styles.arrow} ${styles.miniPaginationArrow}` : styles.arrow}
          onClick={() => onPageClick(currentPage + 1)}
        >
          <Image
            loader={loader}
            src={arrowRight}
            alt="arrow right"
            width={8}
            height={12}
          />
        </button>
      )}
    </div>
  );
};

export default ListPager;
