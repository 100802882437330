import Image from "next/image";
import { useState } from "react";
import { ProgressBarWidget } from "../ProgressBar";
import { getTransmissionPercentage } from "../../helpers/util";
import arrowRight from "../../../public/images/icons/angle-right.png";
import arrowDown from "../../../public/images/icons/angle-down-black.png";
import styles from "../../styles/partylist/PartyListItem.module.scss";

const PartyListItem = ({
  minified = false,
  view,
  partyListDataRes,
  partyList,
  partyListMain,
  partyListAddInfo,
}) => {
  const [isActive, setIsActive] = useState(false);
  const partyListInfo = partyListAddInfo
    ? partyListAddInfo.find((item) => partyList.id === item.id)
    : "";
  const getNominees =
    view !== "default" ? partyListInfo?.nominees : partyList?.nominees;

  const loader = ({ src, width, quality }) => {
    const imageUrl = process.env.SITE_URL;
    return `${imageUrl}${src}?w=${width}&q=${quality || 75}`;
  };

  const greenBars = (val) => {
    let topFiftySix = Array.from({ length: 56 }, (x, i) => i + 1);
    if(topFiftySix.includes(val?.ranking)) {
      return val?.ballot_no !== "11" ? styles.greenBar : styles.test;
    }
  };

  return (
    <li
      className={
        view === "default"
          ? styles.accordionTitle
          : !minified
          ? `${styles.accordionTitle} ${styles.resultsAccordionTitle}`
          : `${styles.accordionTitle} ${styles.resultsAccordionTitle} ${styles.minifiedItems}`
      }
      onClick={() => setIsActive(!minified ? !isActive : isActive)}
      key={partyList.id}
    >
      <ul>
        <li
          className={
            view === "default"
              ? styles.details
              : `${styles.details} ${styles.resultDetails}`
          }
        >
          <p>
            {view === "default"
              ? partyList?.ballot_no
              : partyListDataRes && partyList?.ranking > 0
              ? partyList?.ranking
              : ""}
          </p>
          <h3 className={styles.name}>
            {view !== "default" ? partyListInfo?.alias : partyList?.alias}{" "}
            <br />
            {!minified && (
              <span>
                {view !== "default" ? partyListInfo?.name : partyList?.name}
              </span>
            )}
          </h3>
          {view !== "default" && (
            <div className={styles.result}>
              {view !== "official-results" ? (
                <span>{partyList?.voteCount?.toLocaleString()} </span>
              ) : (
                <span>{partyList?.result_turnout?.toLocaleString()} </span>
              )}
              {view !== "official-results" ? (
                <span>{partyList?.votePercentage}%</span>
              ) : (
                <span>
                  {getTransmissionPercentage(
                    partyList?.result_turnout,
                    partyListMain.canvass_report_total_votes_count
                  )}
                  %
                </span>
              )}
            </div>
          )}
        </li>
        {!minified && (
          <div className={styles.arrow}>
            {isActive ? (
              <Image
                loader={loader}
                src={arrowDown}
                alt="arrow down"
                width={12}
                height={8}
              />
            ) : (
              <Image
                loader={loader}
                src={arrowRight}
                alt="arrow right"
                width={8}
                height={12}
              />
            )}
          </div>
        )}
      </ul>
      {view !== "default" && (
        <div className={styles.progressBarWrapper}>
          {view !== "official-results" ? (
            <ProgressBarWidget
              percentage={
                partyList?.votePercentage !== null ||
                partyList?.votePercentage > 0
                  ? partyList?.votePercentage
                  : 0
              }
            />
          ) : (
            <ProgressBarWidget
              color={greenBars(partyList)}
              percentage={getTransmissionPercentage(
                partyList?.result_turnout,
                partyListMain.canvass_report_total_votes_count
              )}
            />
          )}
        </div>
      )}
      {isActive && (
        <div className={styles.accordionContent}>
          <h6 className={styles.partyListName}>
            {view !== "default" ? partyListInfo?.name : partyList?.name}
          </h6>
          <div className={styles.listWrapper}>
            <div className={styles.nomineesList}>
              <h5>Nominees {getNominees[1] && <span>- First set</span>}</h5>
              {getNominees[0].map((nominee, i) => (
                <div key={i} className={styles.list}>
                  <p>{nominee.nominee_no}</p>
                  <h3>{nominee.raw_name}</h3>
                </div>
              ))}
            </div>
            {getNominees[1] && (
              <div className={styles.nomineesList}>
                <h5> Nominees - second set</h5>
                {getNominees[1].map((nominee, i) => (
                  <div key={i} className={styles.list}>
                    <p>{nominee.nominee_no}</p>
                    <h3>{nominee.raw_name}</h3>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </li>
  );
};

export default PartyListItem;
