import Link from "next/link";
import Image from "next/image";
import useSWRImmutable from "swr/immutable";
import { useState, useEffect, useMemo, useRef } from "react";
import MiniSearch from "minisearch";
import ListPager from "../ListPager";
import PartyListItem from "./PartyListItem";
import { WidgetShare } from "../SocialButtons";
import SearchIcon from "../../../public/images/icons/search-icon.png";
import AscDescIcon from "../../../public/images/icons/sort-asc-desc.png";
import styles from "../../styles/partylist/PartyListWidget.module.scss";
import {
  getTransmissionPercentage,
  getFriendlyFormattedDateTime,
} from "../../helpers/util";

const cfUrl = process.env.API_CF_URL;
const basePath = process.env.SITE_URL;
const PartyListWidget = ({
  homeWidget = false,
  minified = false,
  view,
  regionList,
  subText,
  partyList,
  partyListMain,
  allPartyList,
  url,
  precinctsLocal,
  miniPagination = false,
  viewAllLink = `${url}elections/2022/races/party-list/results`,
  resetWrapper = false,
  locSlug = "philippines",
  isEmbed = false,
  isProvince = false,
}) => {
  const TOTAL_PRECINCTS_PH = regionList
    .map((region) => (region || {}).precincts_count)
    .reduce((a, b) => a + b, 0);
  const TOTAL_PRECINCTS_OAV = (
    regionList.find((region) => region.slug === "oav") || {}
  ).precincts_count;

  const _raceType = "PARTY LIST PHILIPPINES";
  const canvasReportCurrent =
    partyListMain?.canvass_report_certificates_current;
  const canvasReportTotal = partyListMain?.canvass_report_certificates_total;
  const canvasReportTimestamp = partyListMain?.canvass_report_timestamp;
  const listBody = useRef(null);
  const pageSize = 10;
  const [data, setData] = useState(
    view === "default" ? partyList : allPartyList
  );
  const [order, setOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const [isMounted, setIsMounted] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [partyLists, setPartyLists] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsPaginated, setSearchResultsPaginated] = useState([[]]);
  const [queryName, setQueryName] = useState("");
  const fetcher = (url, body) =>
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body,
    }).then(async (res) => {
      return res.json();
    });
  // const [querySector, setQuerySector] = useState('');

  const miniSearch = useMemo(
    () =>
      new MiniSearch({
        // fields: view !== "default" ? ["candidateName", "alias"] : ["name", "alias"],
        fields: ["name", "alias"],
        storeFields: [
          "alias",
          "ballot_no",
          "contest_name",
          "id",
          "name",
          "nominees",
          "self_link",
          "ranking",
          "result_turnout",
          "votePercentage",
          "voteCount",
        ],
      }),
    []
  );

  useEffect(() => {
    setIsMounted(true);
    const getPartyLists = async () => {
      let hasResultsOnPage = true;
      let page = 1;
      setIsSearchLoading(true);
      const results = data.results.sort((a, b) => b.voteCount - a.voteCount);
      // .map((items, i) => ({
      //   rank: i + 1,
      //   ...items,
      // }));
      if (results) {
        hasResultsOnPage = false;
        miniSearch.addAll(results);
      }
      if (!hasResultsOnPage) setIsSearchLoading(false);
    };
    getPartyLists();
  }, [miniSearch, allPartyList, data]);

  const handlePageClick = async (page) => {
    if (!isSearchActive) {
      setStartIndex((page - 1) * pageSize);
      setEndIndex(page * pageSize);
      listBody.current.scrollIntoView({ behavior: "smooth" });
      setCurrentPage(page);
    }

    setCurrentPage(page);
  };

  const handleQueryNameChange = (event) => {
    const query = event.target.value;
    setOrder("desc");
    setQueryName(query);
    if (query === "") {
      setStartIndex(0);
      setEndIndex(pageSize);
    }
  };

  // const handleQuerySectorChange = (event) => {
  //   const query = event.target.value;
  //   setQuerySector(query);
  // };

  useEffect(() => {
    const runSearch = () => {
      const fuzzyMinLength = 5;
      const fuzzyThreshold = 0.2;
      const fuzzierMinLength = 15;
      const fuzzierThreshold = 0.4;
      const searchMinLength = 2;

      if (queryName.length < searchMinLength) {
        setIsSearchActive(false);
        setSearchResults([]);
        setSearchResultsPaginated([]);
        return;
      }

      const searchOptions = {
        fields: ["name", "alias"],
        prefix: true,
        fuzzy: (term) => {
          if (term.length >= fuzzierMinLength) {
            return fuzzierThreshold;
          } else if (term.length >= fuzzyMinLength) {
            return fuzzyThreshold;
          } else {
            return false;
          }
        },
        boost: {
          alias: 2,
        },
      };

      const nameResults = miniSearch.search(queryName, searchOptions);
      // No sector field yet
      // const sectorResults = miniSearch.search(querySector, searchOptions);

      const results = [
        ...nameResults,
        //...sectorResults
      ]
        .sort((a, b) => b.score - a.score)
        .map(
          ({
            alias,
            ballot_no,
            contest_name,
            id,
            name,
            nominees,
            self_link,
            ranking,
            result_turnout,
            votePercentage,
            voteCount,
          }) => ({
            alias,
            ballot_no,
            contest_name,
            id,
            name,
            nominees,
            self_link,
            ranking,
            result_turnout,
            votePercentage,
            voteCount,
          })
        );

      // Split into pages
      const resultsPaginated = Array(Math.ceil(results.length / pageSize))
        .fill()
        .map((_, index) => {
          return results.slice(index * pageSize, index * pageSize + pageSize);
        });

      setCurrentPage(1);
      setIsSearchActive(true);
      setSearchResults(results);
      setSearchResultsPaginated(resultsPaginated);
    };

    runSearch();
  }, [partyLists, miniSearch, queryName, pageSize]);

  const loader = ({ src, width, quality }) => {
    const imageUrl = process.env.SITE_URL;
    return `${imageUrl}${src}?w=${width}&q=${quality || 75}`;
  };

  const { data: rawLatestResultsData, error: latestResultsError } =
    useSWRImmutable(
      [
        `${cfUrl}/latestTransmissionCount`,
        JSON.stringify({
          data: {
            locationSlug:
              view !== "overseas-absentee-voting-results" ? locSlug : "oav",
          },
        }),
      ],
      fetcher,
      {
        refreshInterval: 0,
        shouldRetryOnError: true,
        revalidateOnReconnect: true,
        refreshWhenOffline: true,
        errorRetryInterval: 5000,
        errorRetryCount: 1,
      }
    );
  const latestResultsData = rawLatestResultsData?.result;

  const { data: rawPartyListData, error: partyListError } = useSWRImmutable(
    [
      `${cfUrl}/latestLocationResults`,
      JSON.stringify({
        data: {
          locationSlug:
            view !== "overseas-absentee-voting-results" ? locSlug : "oav",
          raceType: _raceType,
        },
      }),
    ],
    fetcher,
    {
      refreshInterval: 0,
      shouldRetryOnError: true,
      revalidateOnReconnect: true,
      refreshWhenOffline: true,
      errorRetryInterval: 5000,
      errorRetryCount: 1,
    }
  );
  const partyListData = rawPartyListData?.result;

  // if (!partyListData) return <Loader />;
  // if (partyListError) return <p>Something went wrong, Please try again later!</p>;

  allPartyList?.results.forEach((candidate) => {
    if (!partyListData || partyListData.length <= 0) {
      candidate.votePercentage = "0";
      candidate.voteCount = 0;
      return;
    }

    let item = partyListData?.find((x) => x.id === candidate.id);
    if (item === undefined || item === null) {
      candidate.votePercentage = "0";
      candidate.voteCount = 0;
    } else {
      candidate.votePercentage = item.votePercentage;
      candidate.voteCount = item.voteCount;
    }
  });

  searchResultsPaginated[0]?.forEach((candidate) => {
    if (!partyListData || partyListData.length <= 0) {
      candidate.votePercentage = "0";
      candidate.voteCount = 0;
      return;
    }

    let item = partyListData?.find((x) => x.id === candidate.id);
    if (item === undefined || item === null) {
      candidate.votePercentage = "0";
      candidate.voteCount = 0;
    } else {
      candidate.votePercentage = item.votePercentage;
      candidate.voteCount = item.voteCount;
    }
  });

  const handleOrder = async (page) => {
    if (partyListData) {
      setOrder(order === "desc" ? "asc" : "desc");
    }
  };

  const getPrecincts = () => {
    return {
      precintPercentage:
        latestResultsData && latestResultsData.transmissionCount
          ? getTransmissionPercentage(
              latestResultsData.transmissionCount,
              view === "overseas-absentee-voting-results"
                ? TOTAL_PRECINCTS_OAV
                : view === "local-results"
                ? precinctsLocal
                : TOTAL_PRECINCTS_PH
            )
          : "0",
      timeStamp:
        latestResultsData && latestResultsData.timestamp
          ? getFriendlyFormattedDateTime(latestResultsData.timestamp)
          : getFriendlyFormattedDateTime(new Date().getTime()),
    };
  };

  const getShareMessage = () => {
    if (view === "official-results") {
      return "Official 2022 party-list election results #PHVote #WeDecide #PHVoteResults";
    } else {
      return "Partial, unofficial 2022 party-list election results #PHVote #WeDecide #PHVoteResults";
    }
  };

  return (
    <div
      ref={listBody}
      className={`${
        !minified
          ? styles.partyListContainer
          : `${styles.partyListContainer} ${styles.minifiedContainer} ${styles.resetWrapper}`
      } ${homeWidget ? styles.forcedPadding : ""}`}
    >
      <div className={styles.headerPartyList}>
        <div className={styles.titleSubWrapper}>
          <h3 className={styles.titleSubWrapper_title}>PARTY LIST RACE</h3>
          {subText && <h5 className={styles.titleSubWrapper_sub}>{subText}</h5>}
        </div>
        <div className={styles.socialIconsWrapper}>
          <WidgetShare
            loader={loader}
            url={url}
            fbQuote={getShareMessage()}
            tWQuoute={getShareMessage()}
            toolTipId={"toolTip_Default"}
            isMounted={isMounted}
            isEmbedShowed={!isEmbed}
            embedUrl={`${basePath}elections/2022/races/embed/${view}/party-list`}
          />
        </div>
      </div>
      {view === "default" ? (
        <p className={styles.desc}>
          The party list groups with the most votes can each send up to three
          nominees to the House of Representatives, depending on the number of
          votes they garner against the combined total of all groups. They will
          serve for three years starting June 30, 2022.{" "}
          <Link href="https://www.rappler.com/nation/elections/district-party-list-representative-philippines-power-duties/">
            <a target="_blank">Read More...</a>
          </Link>
        </p>
      ) : view === "official-results" ? (
        canvasReportCurrent === canvasReportTotal ? (
          <p className={styles.desc}>
            Final, official results based on <b>{canvasReportCurrent}</b>{" "}
            certificates of canvass.
          </p>
        ) : (
          <p className={styles.desc}>
            Partial, official results based on{" "}
            <b>
              {canvasReportCurrent} of {canvasReportTotal}
            </b>{" "}
            certificates of canvass as of <b>{canvasReportTimestamp}</b>.
          </p>
        )
      ) : (
        <p className={styles.desc}>
          <b>{getPrecincts().precintPercentage}%</b> precincts reporting as of{" "}
          <b>{getPrecincts().timeStamp}</b>. Unofficial-partial results based on
          real-time data from the Comelec transparency server.{" "}
          {isProvince
            ? "Votes for national positions at provincial level include those for highly urbanized and independent component cities situated in the province."
            : ""}
          Based on real-time data from the Comelec media server.
        </p>
      )}

      <div
        className={
          view === "default"
            ? styles.partyListWrapper
            : `${styles.partyListWrapper} ${styles.resultPartyListWrapper}`
        }
      >
        {!minified && (
          <div className={styles.searchPartyList}>
            <div className={styles.search}>
              <p>Party List</p>
              <input
                className={styles.searchBox}
                name="partylist"
                type="text"
                placeholder="Search for Party List Name"
                value={queryName}
                onChange={handleQueryNameChange}
              />
              <div className={styles.searchIcon}>
                <Image
                  loader={loader}
                  src={SearchIcon}
                  alt="search"
                  layout="responsive"
                  width={17.49}
                  height={17.49}
                />
              </div>
            </div>
            {/* <div className={styles.search}>
              <p> Sector</p>
              <input
                className={styles.searchBox}
                name="sector"
                type="text"
                value={querySector}
                onChange={handleQuerySectorChange}
              />
            </div> */}
          </div>
        )}

        {((!minified &&
          isSearchActive &&
          searchResults.length &&
          !isSearchLoading) ||
          (!minified && !isSearchActive && data)) && (
          <div className={styles.pagiDesktop}>
            <ListPager
              totalCount={isSearchActive ? searchResults.length : data.count}
              numberPerPage={pageSize}
              currentPage={currentPage}
              onPageClick={handlePageClick}
            />
            {view !== "default" && (
              <div
                className={styles.sortBtn}
                onClick={() => handleOrder(currentPage)}
              >
                <span>Rank</span>
                <div className={styles.ascDescBtnWrapper}>
                  <Image
                    loader={loader}
                    src={AscDescIcon}
                    alt="right arrow"
                    layout="fixed"
                    width={13.55}
                    height={18.99}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {view !== "default" && (
          <div className={styles.columns}>
            <div className={styles.columns_rankCandidates}>
              <span>Rank</span>
              <span>Party-list</span>
            </div>
            <div className={styles.columns_votesPercent}>
              <span>Votes</span>
              <span>Percent</span>
            </div>
          </div>
        )}

        <ol className={styles.partyList}>
          {isSearchActive && isSearchLoading && (
            <ul>
              <li className={styles.noSearchResults}>Searching...</li>
            </ul>
          )}
          {isSearchActive
            ? searchResults && searchResults.length
              ? searchResultsPaginated[currentPage - 1]
                  .sort((a, b) =>
                    view !== "official-results"
                      ? b.voteCount - a.voteCount
                      : a.ranking - b.ranking
                  )
                  .map((item) => (
                    <PartyListItem
                      key={item.id}
                      view={view}
                      partyListDataRes={partyListData}
                      partyList={item}
                      partyListMain={partyListMain}
                      partyListAddInfo={allPartyList?.results}
                    />
                  ))
              : !isSearchLoading && (
                  <ul>
                    <li className={styles.noSearchResults}>
                      No party lists found for &ldquo;{queryName}&rdquo;.
                    </li>
                  </ul>
                )
            : data &&
              data.results &&
              data.results
                .sort((a, b) =>
                  view !== "official-results"
                    ? order === "desc"
                      ? b.voteCount - a.voteCount
                      : a.voteCount - b.voteCount
                    : order === "desc"
                    ? a.ranking - b.ranking
                    : b.ranking - a.ranking
                )
                .map((items, i) => ({
                  rank:
                    partyListData && partyListData.length
                      ? order === "desc"
                        ? i + 1
                        : partyListData.length - i
                      : -1,
                  ...items,
                }))
                .slice(startIndex, endIndex)
                .map((item) => (
                  <PartyListItem
                    minified={minified}
                    key={item.id}
                    view={view}
                    partyListDataRes={partyListData}
                    partyList={item}
                    partyListMain={partyListMain}
                    partyListAddInfo={allPartyList?.results}
                  />
                ))}
        </ol>

        {minified && !resetWrapper && (
          <div className={styles.btnViewAll}>
            <Link
              href={viewAllLink}
              className={styles.button}
              onClick={() => {
                setIsShowAll(true);
              }}
              passHref
            >
              <a className={styles.view}>View All</a>
            </Link>
          </div>
        )}
        {((!minified &&
          isSearchActive &&
          searchResults.length &&
          !isSearchLoading) ||
          (!minified && !isSearchActive && data)) && (
          <div className={styles.resultsFooter}>
            {view !== "overseas-absentee-voting-results" && (
              <h6>*Already includes Overseas Absentee Votes</h6>
            )}
            <ListPager
              totalCount={isSearchActive ? searchResults.length : data.count}
              numberPerPage={pageSize}
              currentPage={currentPage}
              onPageClick={handlePageClick}
            />
          </div>
        )}
        {minified && miniPagination && (
          <ListPager
            miniPagination={miniPagination}
            totalCount={isSearchActive ? searchResults.length : data.count}
            numberPerPage={pageSize}
            currentPage={currentPage}
            onPageClick={handlePageClick}
          />
        )}
      </div>
      <div
        className={
          minified ? styles.minifiedSocialsWrapper : styles.socialWrapper
        }
      >
        <WidgetShare
          loader={loader}
          url={url}
          fbQuote={getShareMessage()}
          tWQuoute={getShareMessage()}
          toolTipId={"toolTip_Default"}
          isMounted={isMounted}
          isEmbedShowed={!isEmbed}
          embedUrl={`${basePath}elections/2022/races/embed/${view}/party-list`}
        />
      </div>
    </div>
  );
};

export default PartyListWidget;
